<template>
    <div class="wrapper">
        <div class="grid gap-20">

            <ActionBar title="Referrals"></ActionBar>

            <div class="page-nav">
                <router-link :to="{ name: item.to }" :key="key" v-for="(item, key) of nav">
                    <span class="icon"><i :class="item.icon"></i></span>
                    <span class="name">{{ item.name }}</span>
                </router-link>
            </div>
        </div>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nav: [
                {
                    to: "AdminReferralsMain",
                    icon: "fa-solid fa-users",
                    name: "Users",
                },
                {
                    to: "AdminReferralsPayments",
                    icon: "fa-solid fa-coins",
                    name: "Payments",
                }
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    // max-width: 1300px;
    // margin: 0 auto;
    display: grid;
    grid-template-columns: 250px minmax(300px, 1fr);
    gap: 30px;
    align-items: start;
}

.page-nav {
    border-right: 1px solid $borderColor;
    padding: 0 10px 0 0;

    a {
        display: flex;
        height: 36px;
        align-items: center;
        padding: 0 10px;
        border-radius: 9px;
        color: rgba(0, 0, 0, 0.5);
        gap: 10px;
        text-transform: capitalize;

        &.router-link-exact-active {
            // background: $themeColor1;
            color: $themeColor2;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }

        .icon {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>